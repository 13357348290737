import React, { useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import Seo from "../../components/seo";
import BlogHeaderPage from '../../utils/BlogHeaderPage';
import Header from '../../components/Header/Header-2';
import Parser  from 'react-html-parser'
import { useStaticQuery, graphql } from "gatsby";
import { isSSR, updatePageContent, submitActiveCampaignForm } from '../../utils/utils';
const getdata = graphql`
{
  wpgraphql {
    posts(where: {categoryName: "Medical Device"}, first: 10) {
      nodes {
        slug
        title
        id
        content
        date
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
    page(id: "cG9zdDo5OTcx") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
  `
const MedicalDevices = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  const latestPost = data.wpgraphql.posts.nodes;
  useEffect(() => {
    // load OptinMonster
    if (window.om210904_195537) {
      window.om210904_195537.reset();
    }
    // 
    document.addEventListener('om.Campaign.parseCampaigns', function(event) {
      const email = document.getElementById('eminence-field-email').value;
      // submit campaign
      if (email) {
        const data = { name: email, email: email };
        submitActiveCampaignForm(data, false);
      }
    });
  });
  return (
    <Layout>
      <Seo title={common.title} description={common.seo.metaDesc} canonical={ `${common.uri}`} seo={common.seo}  />
      
      <Header home={false} menu='info' />
      <main>
        <section class="press-banner-title py-5">
          <div class="container">
            <h2 class="page-title text-white">Patent Information</h2>
          </div>
        </section>

        <section className='py-5'>
          <div className="container">
            <div className='row'>
              <div className='col-md-9'>
                <h2 className='display-5 mb-3 border-bottom d-inline-block pb-3'>{ common.title }</h2>
                  <div className='mb-5'>
                    <article>
                      <>
                        <div className="accordion-content">
                        {Parser(isSSR() ? common.content : updatePageContent(common.content, latestPost))}
                        </div>
                      </>
                    </article>
                  </div>
              </div>
              <div className="col-md-3">
                <div id="om-gostrrijfkan58snueeg-holder"></div>
              </div>
            </div>
          </div>
        </section>
      </main>

    </Layout>
  );
};

export default MedicalDevices;
